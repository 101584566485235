<template>
  <div class="q-pa-md">
    <q-responsive :ratio="1 / 1" style="width: 500px; max-width: 100%;">
      <q-carousel
        swipeable
        animated
        v-model="slide"
        v-model:fullscreen="fullscreen"
        ref="carousel"
        infinite
      >
        <template v-for="(data, idx) in imageList" :key="idx">
          <q-carousel-slide :name="idx">
            <q-scroll-area class="fit">
              <q-img class="rounded-borders" :src="data.image" />
            </q-scroll-area>
          </q-carousel-slide>
        </template>
        <template v-slot:control>
          <q-carousel-control
            position="top-left"
            :offset="[18, 18]"
            class="q-gutter-xs"
          >
            <q-btn
              push
              round
              dense
              color="orange"
              text-color="black"
              icon="arrow_left"
              @click="$refs.carousel.previous()"
            />
            <q-btn
              push
              round
              dense
              color="orange"
              text-color="black"
              icon="arrow_right"
              @click="$refs.carousel.next()"
            />
          </q-carousel-control>
          <q-carousel-control position="top-right" :offset="[18, 18]">
            <q-btn
              push
              round
              dense
              color="white"
              text-color="primary"
              :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
              @click="fullscreen = !fullscreen"
            />
          </q-carousel-control>
        </template>
      </q-carousel>
    </q-responsive>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    imageList: Object
  },
  setup() {
    return {
      slide: ref(1),
      fullscreen: ref(false)
    };
  }
};
</script>

<style>
.rounded-borders {
  max-height: 95vh;
}

.rounded-borders img {
  object-fit: contain !important;
}
</style>
