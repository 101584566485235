<template>
  <div class="q-pa-md row items-start q-gutter-none">
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2023 第四屆世界皮革創意設計大賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="pige04ImageList"
      />
      <q-card-section>
        江槺耀 鞋類設計組-金獎<br />
        吳謹安 鞋類設計組-銀獎<br />
        徐嘉怡 鞋類設計組-優選<br />
        林羽珮 時尚配件組-優選<br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          112年全國學生美術比賽版畫類
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="banhuaImageList"
      />
      <q-card-section>
        王昱淇 大專非美術組特優
        <br /><br /><br /><br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2022AW台北時裝週
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="xinxiuImageList"
      />
      <q-card-section>
        饒文濤、張喬盈同學 人氣設計潛力新秀獎<br />
        融合東西方文化，如同現實的平行時空<br />
        用過去、現在與未來元素交錯的張力創造出全新的設計<br />
        <br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2022第三屆世界皮革創意設計大賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="pige03ImageList"
      />
      <q-card-section>
        任健輝 時尚設計類銀獎 楊珮欣 配件設計類優選<br />
        張佳雯 鞋類設計金獎 林知璇 鞋類設計銀獎<br />
        馮穎梅 鞋類設計銅獎 楊巧瑜 鞋類設計優選
        <br /><br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          海峽兩岸服裝設計比賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="jinshangImageList"
      />
      <q-card-section>
        蔡佩宸、林嘉鈴同學，優秀金裳獎<br />
        Special thanks to：<br />
        CRE.a hair salon Miu Miu 造型總監率領設計團隊協助，<br />
        分享獲獎榮譽（每一個造型都超好看！）<br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2021新加坡金沙藝術設計大賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="jinshaImageList"
      />
      <q-card-section>
        任健輝 服裝設計-銅奬
        <br /><br /><br /><br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2021第二屆世界皮革創意設計大賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="pige02ImageList"
      />
      <q-card-section>
        有34所大學參與，系上同學們在540件作品脫穎而出。<br />
        榮獲總冠軍🏆<br />
        鞋顃設計金獎、銀獎、銅獎及佳作兩名。<br />
        時尚設計類銀獎、銅獎及佳作兩名。<br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          日本2020 Nagoya Fashion Contest
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="nagoyaImageList"
      />
      <q-card-section>
        鄭佳儀 入圍
        <br /><br /><br /><br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2020第一屆世界皮革創意設計大賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="pige01ImageList"
      />
      <q-card-section>
        王佳尹 服裝組金獎 梁煒圯 服裝組銅獎 游閔盛 服裝組佳作<br />
        朱方慈 服裝組佳作 曾湘尹 服裝組佳作<br />
        謝坤霖 鞋類組金獎 張宛渝 鞋類組銀獎 林宥霖 鞋類組銅獎<br />
        曹謙益 鞋類組佳作 施唯心 鞋類組佳作
        <br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2020金士頓X中衛亞太區口罩設計大賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="kingstonImageList"
      />
      <q-card-section>
        柯儀晨 最佳人氣第二名<br /><br />
        <br /><br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2020環保時尚設計菁英競賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="huangbaoImageList"
      />
      <q-card-section>
        陳雨瑄 第三名<br /><br />
        <br /><br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2020聚陽人文發展教育基金會<br />
          衣舊要愛創意設計競賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="juyangImageList"
      />
      <q-card-section>
        曾琬凌蔡耀東施亮熙 優勝<br />
        何伊涵沈書亦陳鈺嫻 優選<br />
        明舉綱蔣宛庭林莉恩何瑾泓 佳作<br />
        駱蔚森王佳尹 佳作 人氣獎<br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          大港潮起來！2020高雄青時尚大賞
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="dagangImageList"
      />
      <q-card-section>
        廖家平 設計賞<br />
        游閔盛 新秀獎<br />
        <br />
        <br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2020哈客WAY印花設計
        </div>
      </q-card-section>
      <ExhibitionImage class="my-exhibition-image" :imageList="hakeImageList" />
      <q-card-section>
        游芷榕 2020哈客WAY印花設計 條紋客家襟衫版-首獎<br />
        曾琬凌 2020哈客WAY印花設計 美好哈客棉T版-首獎<br />
        <br />
        <br />
      </q-card-section>
    </q-card>
    <q-card class="my-card col-12 col-md-4">
      <q-card-section>
        <div class="text-h5 q-mb-xs" style="height: 24px">
          2020陶瓷服裝創意大賽
        </div>
      </q-card-section>
      <ExhibitionImage
        class="my-exhibition-image"
        :imageList="taociImageList"
      />
      <q-card-section>
        陳怡君 第一名暨企業獎<br />
        盧凱妮 第二名暨企業獎 吳佳樺 第三名暨企業獎<br />
        楊 洋 佳作 廖舒妍 佳作 鄭宇容 佳作<br />
        游芷榕 佳作 游閔盛 企業獎 黃莉瀅 企業獎<br />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import ExhibitionImage from "@/components/ExhibitionImage.vue";

export default {
  setup() {
    return {
      xinxiuImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3026_9014093_78607.jpeg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3026_9014093_78607.jpeg"
        }
      ],
      jinshangImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2929_1754914_35225.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2934_3500053_35327.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2930_5674868_35242.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2931_4956125_35258.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2932_5141271_35280.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2933_4144328_35299.jpg"
        }
      ],
      pige02ImageList: [
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2936_925106_35812.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2938_2164062_35848.jpg"
        },
        {
          image:
            "https://rc024.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2937_5223364_35831.jpg"
        }
      ],
      taociImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3600_1832224_64101.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3602_2330799_72068.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3599_1527878_64086.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3601_4317046_64115.jpg"
        }
      ],
      hakeImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3603_8920505_71835.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3603_8920505_71835.jpg"
        }
      ],
      dagangImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3604_3355909_72113.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3605_8932570_72148.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3606_4231752_72194.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3607_2730105_72229.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3608_8198139_72279.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3625_3234153_72426.jpg"
        }
      ],
      juyangImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3609_9082503_67142.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3610_2602098_67155.jpg"
        }
      ],
      huangbaoImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3611_7308290_67288.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3612_1619085_67303.jpg"
        }
      ],
      kingstonImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3613_5249175_67420.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3613_5249175_67420.jpg"
        }
      ],
      pige01ImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3614_5283532_71616.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3614_5283532_71616.jpg"
        }
      ],
      nagoyaImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3615_7676779_67633.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3615_7676779_67633.jpg"
        }
      ],
      jinshaImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3616_3435933_71886.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3617_6287701_71931.jpg"
        }
      ],
      pige03ImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3618_4572468_72017.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3618_4572468_72017.jpg"
        }
      ],
      banhuaImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3619_1441072_71971.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3619_1441072_71971.jpg"
        }
      ],
      pige04ImageList: [
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3620_3764842_71659.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3621_3037683_68256.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3622_2134823_68280.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3623_8604483_72499.jpg"
        },
        {
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3624_6784072_72534.jpg"
        }
      ]
    };
  },
  components: {
    ExhibitionImage
  }
};
</script>
