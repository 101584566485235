<template>
  <q-page>
    <div class="award">
      <!-- <h1>This is an award page</h1> -->
      <HonorList />
    </div>
  </q-page>
</template>

<script>
// @ is an alias to /src
// import AwardTable from "@/components/AwardTable.vue";
import HonorList from "@/components/HonorList.vue";

export default {
  setup() {
    return {};
  },
  name: "Award",
  components: {
    // AwardTable,
    HonorList
  }
};
</script>
